<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / Add Team
        </h1>
      </v-flex>
      <v-flex xs12 class="mb-5">
        <h3><b>PROJECT : </b>{{ project.name }}</h3>
      </v-flex>
      <v-flex xs12 md6 class="grey darken-4 pa-2" style="border-radius: 10px">
        <v-text-field
          single-line
          outlined
          label="Type to search.."
          prepend-inner-icon="mdi-magnify"
          v-model="searchText"
        ></v-text-field>
        <v-layout row wrap class="pa-5">
          <v-flex
            xs12
            md12
            lg6
            v-for="item in filteredUsers"
            :key="item._id"
            class="d-flex mt-4 align-center"
          >
            <div class="mr-5">
              <v-icon v-if="isUserInTeam(item._id)" class="primary--text" @click="removeFromTeam(item._id)">mdi-check-circle</v-icon>
              <v-icon v-else @click="addToTeam(item._id)">mdi-plus-circle</v-icon>
            </div>
            <img
              :src="item.display_picture"
              alt=""
              style="width: 48px; height: 48px; border-radius: 24px"
              v-if="item.display_picture"
            />
            <div
              style="
                width: 48px;
                height: 48px;
                background-color: #b6b6b6;
                border-radius: 24px;
              "
              v-else
            ></div>
            <div class="pl-3">
              <p class="pa-0 ma-0">{{ item.name }}</p>
              <p class="pa-0 ma-0 accent--text" v-if="item.department">{{ item.department.name }}</p>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex lg2></v-flex>
      <v-flex md12 lg4 class="">
        <div>
          <div class="d-flex">
            <h2 class="accent--text">Team</h2>
          </div>
          
          <div v-for="item in project.team" :key="item._id" class="d-flex mt-4">
            <img :src="item.display_picture" alt="" style="width: 48px;height:48px;border-radius: 24px;" v-if="item.display_picture">
            <div style="width: 48px;height: 48px;background-color: #212121; border-radius: 24px;" v-else></div>
            <div class="pl-3">
              <p class="pa-0 ma-0">{{ item.name }}</p>
              <p class="pa-0 ma-0 accent--text" v-if="item.department">{{ item.department.name }}</p>
            </div>
          </div>
        </div>
        
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "../../config";
import Axios from "axios";
export default {
  data() {
    return {
      users: [],
      searchText: "",
      project: {},
    };
  },
  mounted() {
    this.fetchUser();
    this.fetchProject();
  },
  computed: {
    filteredUsers() {
      const query = this.searchText.toLowerCase();
      if (query.length >= 3) {
        return this.users.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query)
        );
      } else {
        return this.users;
      }
    },
  },
  methods: {
    async removeFromTeam(id){
      let url = BASE_URL + "/project/team/remove/"+this.$route.params.project
      let payload = {
        user : id
      }
      let {data} = await Axios.patch(url,payload)
      this.fetchProject()
    },
    async addToTeam(id){
      let url = BASE_URL + "/project/team/add/"+this.$route.params.project
      let payload = {
        user : id
      }
      let {data} = await Axios.patch(url,payload)
      this.fetchProject()
    },
    isUserInTeam(userId) {
      return this.project.team.some((user) => user._id === userId);
    },
    async fetchUser() {
      let url = BASE_URL + "/user";
      let { data } = await Axios.get(url);
      this.users = data;
      
    },
    async fetchProject() {
      let url = BASE_URL + "/project/" + this.$route.params.project;
      let { data } = await Axios.get(url);
      this.project = data;
    },
  },
};
</script>